<template>
    <loader :loading="loading">
        <form>
            <columns>
                <column>
                    <text-input required classes="is-rounded is-medium" description="A descriptive name for this report"
                        :error="$root.errors.name" :value="report.name" @input="updateName">
                        Name
                    </text-input>
                </column>
                <column>
                    <select-input required :error="$root.errors.type" :items="types" classes="is-rounded is-medium"
                        description="Select the type of report" :value="report.type" @input="updateType">
                        Type
                    </select-input>
                </column>
                <column class="is-narrow">
                    <select-input 
                        required 
                        :items="ranges" 
                        :value="report.range" 
                        @input="updateRange"
                        :error="$root.errors.range" classes="is-medium is-rounded"
                        description="Define the range in which this report is generated">
                        Date Range
                    </select-input>
                </column>
            </columns>
            <columns>
                <column>
                    <columns>
                        <column>
                            <data-selector multiple searchable classes="is-rounded is-medium" value-key="id"
                                label-key="name" prompt-label="All" :value="report.customers" @input="updateCustomers"
                                :items="customer_list"
                                :description="report.customers.length ? 'This report will be scoped to the selected customers' : 'All customers will be included'">
                                Select Customers
                            </data-selector>
                        </column>
                    </columns>
                </column>
                <column>
                    <columns>
                        <column>
                            <data-selector multiple classes="is-rounded is-medium" value-key="id" label-key="name"
                                prompt-label="All" searchable :value="report.locations" @input="updateLocations"
                                :items="availableLocations"
                                :description="report.locations.length ? 'This report will be scoped to the selected locations' : 'All locations will be included'">
                                Select Locations
                            </data-selector>
                        </column>
                    </columns>
                </column>
            </columns>
            <columns>
                <column>
                    <select-input required :items="areas" :error="$root.errors.area" classes="is-rounded is-medium"
                        description="A list of statuses to include" prompt-label="All" :value="report.area"
                        @input="updateArea">
                        Status
                    </select-input>
                </column>
                <column>
                    <data-selector required multiple searchable tag-class="is-rounded is-medium"
                        delete-button-class="is-rounded is-medium is-danger" classes="is-rounded is-medium"
                        description="A list of formats to include" :error="$root.errors.formats"
                        :value="report.formats" @input="updateFormats" :items="formats">
                        Formats
                    </data-selector>
                </column>
            </columns>

            <columns>
                <column>
                    <text-area :value="report.intro" @input="updateIntro">Introduction</text-area>
                </column>
            </columns>

            <columns v-if="report.type">
                <column>
                    <component 
                        ref="builder"
                        editing
                        :selected-checklists="report.checklists"
                        :is="`report-builder-meta-${report.type}`"
                    />
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button :working="working" @submit="updateReport">
                        Save
                    </submit-button>
                </column>
            </columns>
        </form>
    </loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import flatMap from 'lodash/flatMap'
import intersection from 'lodash/intersection'
import EquipmentMeta from '../../store/models/reporting/EquipmentMeta'
import Report from '../../store/models/Report'

export default {

    data: () => ({
        loading: false,
        working: false,
        types: [
            { value: 'equipment', label: 'Equipment Summary' },
            { value: 'checklist', label: 'Checklist Summary' },
        ],
        areas: [
            { value: 'progress', label: 'Progress' },
            { value: 'work_order_failure', label: 'Work Order Failures', for: ['user', 'team'] },
            { value: 'work_order_passes', label: 'Work Order Passes' },
            { value: 'volume', label: 'Volume' },
        ],
        ranges: [
            { value: 'day', label: 'Daily' },
            { value: 'week', label: 'Weekly' },
            { value: 'month', label: 'Monthly' },
            // { value: '6_months', label: '6 Months' },
            // { value: 'annual', label: 'Annual' },
        ]
    }),

    beforeCreate() {
        Promise.all([
            this.$store.dispatch('customer/loadCustomerList'),
            this.$store.dispatch('equipmentClassification/loadList')
        ]).then(() => {
            if(this.report.type === 'equipment') {
                this.mapOutEquipmentTypesAndProperties()
            }
        }).finally(() => {
            this.loading = false
        })
        
    },

    methods: {
        ...mapMutations('report', [
            'updateName',
            'updateType',
            'updateArea',
            'updateFormats',
            'updateCustomers',
            'toggleIncludeAllCustomers',
            'toggleIncludeAllLocations',
            'updateLocations',
            'updateIntro',
            'updateEquipmentClassifications',
            'updateRange'
        ]),
        updateReport() {
            this.working = true
            this.$store.dispatch('report/update').then(() => {
                this.$toast.success('Saved')
                this.working = false
            }).catch(() => this.working = false)
        },
        mapOutEquipmentTypesAndProperties() {
            const equipment_props = EquipmentMeta.availableColumns().map(column => column.attribute)
            const base_props = Report.baseColumns().map(column => column.attribute)
            const banned_columns = [...equipment_props, ...base_props]
            let props = this.report.columns.filter(column => {
                return banned_columns.includes(column.attribute) === false
            }).map(column => column.attribute)
            const selected_equipment_classifications = this.equipment_classification_list.filter(classification => {
                const mapped_properties = classification.meta.map(attribute => attribute.lookup_name)
                return intersection(mapped_properties, props).length === props.length
            })
            this.updateEquipmentClassifications(selected_equipment_classifications)

            this.$refs.builder.setSelectedProperties(
                this.report.columns,
                this.report.columns.filter(column => props.includes(column.attribute))
            )
            return props
        }
    },

    computed: {
        ...mapGetters('report', [
            'report',
            'formats'
        ]),
        ...mapGetters('customer', [
            'customer_list'
        ]),
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ]),
        availableLocations() {
            const customer_list = this.report.all_customers
                ? this.customer_list
                : this.customer_list.filter(customer => this.report.customers.indexOf(customer) > -1)

            return flatMap(
                customer_list,
                customer => customer.locations
            )
        }
    }

}
</script>