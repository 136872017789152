export default class EquipmentMeta {
    constructor(meta = {}) {
        this.classifications = meta.classifications || []
    }

    static availableColumns() {
        return [
            { name: 'name', label: 'Name', type: 'column', attribute: 'name' },
            { name: 'code', label: 'Code', type: 'relation', attribute: 'code.value' },
            { name: 'assigned_user', label: 'Assigned User', type: 'relation', attribute: 'user.full_name' },
        ]
    }
}